import gql from 'graphql-tag';
import React, { useEffect } from 'react';

import { getApollo, getEventLogger } from '../../index';
import { setBatchingContext, setUserContext } from '../apolloLinkContext';
import { setCommonTimingProps } from '../event-logger';
import { BoomerangOptions } from '../boomerang';
import { AnonymousAppQuery, AppQuery, JSON } from '../gqlTypes';
import storeAndReturnSubscriptionInfo from '../storeSubscriptionInfo';
import { assignPendoIds } from '../pendo/utils';
import initThirdPartyIntegrations from './initThirdPartyIntegrations';
import { useUserAuthenticationState, UserAuthenticationState } from '../session-timer';
import { performThirdPartyInitForAnonymousUsers } from './performThirdPartyInit';

const appQuery = gql`
  query AppQuery {
    dashboard {
      id
      user_id
      userIntegrationHash
      sessionId
      permission
      employee {
        id
        email
        allStatus ## This field is not used, but keeping it here because it's used in some commented-out code.
      }
      company {
        id
        billingAccount {
          id
          companyAccountZuoraId
        }
      }
      switches
    }
  }
`;

// TODO: unify this with the query from components/network to avoid 2 queries for the same thing
const anonymousAppQuery = gql`
  query AnonymousAppQuery {
    switches
  }
`;

interface AppInitProps {
  /** redirect to '/dashboard' if current employee is terminated */
  redirectIfTerminated?: boolean;
  disableNice?: boolean;
  enableArkoseForApp?: boolean;
  boomerangOptions?: BoomerangOptions;
  // FS will always be enabled for trial companies, but this flag can be used to enable for the entire app
  enableFullStoryForApp?: boolean;
  noCompanyInContext?: boolean;
}

type FullInitData = {
  isAdmin?: boolean;
  switches: JSON;
  employeeId: string | null;
  companyId: string | null;
  userId: string | null;
  sessionId: string | null;
  companyAccountZuoraId: string | null;
  employeeEmail: string | null;
  userIntegrationHash: string | null;
};
type InitData = { switches: JSON } | FullInitData;

function performInit(data: InitData) {
  const {
    isAdmin,
    employeeId,
    companyId,
    userId,
    switches,
    sessionId,
    companyAccountZuoraId,
    employeeEmail,
  } = data as FullInitData;
  // TODO (vberteanu)
  // Add back this check once we have a reliable way of retrieving the employee status via GQL.
  // Currently employees of type AD have empty status and that breaks the EmployeeStatus type in schema.
  // We should not rely on the Ter status for the redirect, we should rely on the Zapp status.

  // if (this.props.redirectIfTerminated && dashboard.employee && dashboard.employee.allStatus === 'Ter') {
  //   setTimeout(() => {
  //     window.location.href = '/dashboard';
  //   });
  //   return;
  // }

  setUserContext({
    employeeId,
    companyId,
    userId,
  });
  setBatchingContext(switches.limited_gql_batching);
  setCommonTimingProps({ limitedGqlBatching: switches.limited_gql_batching });

  const eventLogger = getEventLogger();
  eventLogger.addClientMeta({
    sessionId,
  });

  eventLogger.setCurrentUserData({
    employeeId,
    companyId,
    userId,
    companyAccountZuoraId,
  });

  // Add pendo ids once initialized
  assignPendoIds(pendoIds => {
    eventLogger.setCurrentUserData({ pendoIds });
  });

  eventLogger.setPrivateData({
    userEmail: employeeEmail,
  });

  // Hack for now to avoid this is company-hub to fix issues where session is losing companyId
  if (!window.location.pathname.includes('/app/company-hub/')) {
    // NOTE: intentionally don't await for the function
    storeAndReturnSubscriptionInfo({ isAdmin });
  }
}

const AppInit: React.FC<AppInitProps> = props => {
  const { boomerangOptions, disableNice, enableArkoseForApp, enableFullStoryForApp, noCompanyInContext } = props;
  const apolloClient = getApollo();
  const authState = useUserAuthenticationState();

  useEffect(() => {
    if (authState === UserAuthenticationState.unknown) return;
    if (authState === UserAuthenticationState.anonymous) {
      apolloClient
        .query<AnonymousAppQuery.Query>({
          query: anonymousAppQuery,
          context: { headers: { 'IS-BACKGROUND-QUERY': true } },
          // Temporarily ignoring errors so issues with isCompanyOnboardingAccessible fetch don't break integrations
          errorPolicy: 'ignore',
        })
        .then(result => {
          const switches = result.data?.switches;
          performThirdPartyInitForAnonymousUsers(switches, {
            enableFullStoryForApp,
            enableArkoseForApp,
            boomerangOptions,
          });
          performInit({ switches });
        });
    } else {
      initThirdPartyIntegrations({
        boomerangOptions,
        disableNice,
        enableArkoseForApp,
        enableFullStoryForApp,
        noCompanyInContext,
      });

      getApollo()
        .query<AppQuery.Query>({
          query: appQuery,
          context: { headers: { 'IS-BACKGROUND-QUERY': true } },
          // Temporarily ignoring errors so issues with isCompanyOnboardingAccessible fetch don't break integrations
          errorPolicy: 'ignore',
        })
        .then(result => {
          if (!result.errors || result.errors.length === 0) {
            const { dashboard } = result.data;
            const isAdmin = dashboard?.permission?.isAdmin;

            performInit({
              isAdmin,
              sessionId: dashboard.sessionId,
              switches: dashboard.switches,
              employeeId: dashboard.employee && dashboard.employee.id,
              companyId: dashboard.company && dashboard.company.id,
              userId: dashboard.user_id,
              companyAccountZuoraId:
                dashboard.company &&
                dashboard.company.billingAccount &&
                dashboard.company.billingAccount.companyAccountZuoraId,
              employeeEmail: dashboard.employee && dashboard.employee.email,
              userIntegrationHash: dashboard.userIntegrationHash,
            });
          }
        });
    }
  }, [
    apolloClient,
    authState,
    boomerangOptions,
    disableNice,
    enableArkoseForApp,
    enableFullStoryForApp,
    noCompanyInContext,
  ]);

  return null;
};

export default AppInit;
