import bootDrift from '../boot-drift';
import { ThirdPartyIntegrationQuery } from '../gqlTypes';
import { checkAndBootOptimizely } from '../bootOptimizely';
import bootNice from '../boot-nice';
import { bootArkose } from '../arkose/boot-arkose';
import bootSegment from '../segment/boot-segment';
import bootFullstory from '../fullstory/boot-fullstory';
import bootPendo from '../pendo/boot-pendo';
import { BoomerangOptions } from '../boomerang';
import { applySwitchesToPerformanceAnalytics } from '../analyticsInitializer';
import { CONTRACTOR_PAYMENTS } from '../constants';

declare global {
  interface Window {
    fireflyKillswitch: boolean;
    // TODO: add type for fields we use in optimizely.
    optimizely: {
      push: (event: { type: string; eventName: string }) => void;
    };
  }
}

enum ProspectAccountDemoType {
  sales = 'sales',
  customer = 'customer',
}

enum ProspectAccountSalesChannel {
  selfServe = 'selfServe',
  salesAssisted = 'salesAssisted',
}

enum ProspectAccountType {
  demo = 'demo',
  freeLimitedTrial = 'freeLimitedTrial',
}

type AnonymousParams = {
  enableFullStoryForApp?: boolean;
  enableArkoseForApp?: boolean;
  boomerangOptions?: BoomerangOptions;
};
export function performThirdPartyInitForAnonymousUsers(switches: any, params: AnonymousParams) {
  const { enableArkoseForApp, enableFullStoryForApp, boomerangOptions } = params;
  checkAndBootOptimizely({ switches, isCustomerDemo: false });

  bootPendo({
    switches,
    isRealCompany: false,
    isFreeLimitedTrialOrCustomerDemo: false,
    isSelfServe: false,
    isAnonymousUser: true,
    // Pass an empty string for anonymous users https://support.pendo.io/hc/en-us/articles/360032202751-Anonymous-Visitors,
    userIntegrationHash: '',
    employeeId: '',
    companyId: '',
  });
  bootArkose({ switches, enableArkoseForApp });
  // NOTE: we intentionally don't pass a userID to segment so it recognizes the user
  // as anonymous and later identifies them when we have their email (identity)
  bootFullstory({ switches, enableFullStoryForApp, isAnonymous: true });
  bootSegment({ switches, isAnonymous: true });

  // Boot boomerang
  applySwitchesToPerformanceAnalytics(switches, boomerangOptions || {});
}

type Params = AnonymousParams & {
  /**
   * A flag that can be set when using renderApp and AppInit to disable NICE bot
   */
  disableNice?: boolean;
  queryResultData: ThirdPartyIntegrationQuery.Query;
  /**
   * Whether a company is in context.
   * A flag that can be set when using renderApp and AppInit.
   * For example, in company-hub, there is no company in context.
   */
  noCompanyInContext?: boolean;
};

export default function performThirdPartyInit(params: Params) {
  const {
    boomerangOptions,
    queryResultData,
    disableNice,
    enableArkoseForApp,
    enableFullStoryForApp,
    noCompanyInContext,
  } = params;
  const { dashboard, inImplementation } = queryResultData;

  const {
    company,
    employee,
    partnerUserCompanyId,
    partnerUserIntegrationHash,
    permission,
    switches,
    userIntegrationHash,
  } = dashboard;

  const companyId = company?.id;
  const isRealCompany = company?.isReal;
  const isPartnerUser = partnerUserIntegrationHash && partnerUserCompanyId;
  const partnerUser = isPartnerUser
    ? {
        userIntegrationHash: partnerUserIntegrationHash as string,
        companyId: partnerUserCompanyId as string,
      }
    : null;
  const workEmail = employee?.email;
  const supportId = employee?.supportId;
  const isManager = employee?.isManager || false;
  const employeeStatus = employee?.allStatus;
  const isContractorPayments = dashboard?.features?.includes(CONTRACTOR_PAYMENTS);
  const isAdmin = permission?.isAdmin;
  const prospectAccount = company?.prospectAccount;
  const isProspectAccount: boolean = !!prospectAccount;
  const isDemo: boolean = prospectAccount?.type === ProspectAccountType.demo;
  const isFreeLimitedTrial: boolean = prospectAccount?.type === ProspectAccountType.freeLimitedTrial;
  const isSalesDemo: boolean = prospectAccount?.demoType === ProspectAccountDemoType.sales;
  const isSelfServe: boolean = prospectAccount?.salesChannel === ProspectAccountSalesChannel.selfServe;
  const isFreeLimitedTrialOrCustomerDemo = isProspectAccount && !isSalesDemo;
  // Support chat bot is only for customers or sales since they like to Demo this to customers
  // (exclude SS demo/trials we have either Drift)
  const isPayingCustomerOrSales = !isProspectAccount || isSalesDemo;

  const isInEmbeddedNativeView =
    window.ZENEFITS_MOBILE_INTEGRATION && window.ZENEFITS_MOBILE_INTEGRATION.isEmbeddedNativeView;

  if (!switches.drift_killswitch) {
    bootDrift();
  }

  const shouldBootNice =
    !disableNice &&
    !isInEmbeddedNativeView &&
    isPayingCustomerOrSales &&
    // To use NICE bot, a company in context is required to get initial data such as HR contact information.
    !noCompanyInContext;

  if (shouldBootNice) {
    bootNice({ isAdmin, isContractorPayments, isInImplementation: inImplementation });
  }

  bootFullstory({
    switches,
    enableFullStoryForApp,
    isFreeLimitedTrialOrCustomerDemo,
    userIntegrationHash,
    isFreeLimitedTrialCompany: isFreeLimitedTrial,
    trialAccountEmail: prospectAccount?.consumerEmail,
  });

  bootPendo({
    switches,
    partnerUser,
    supportId,
    isRealCompany,
    isFreeLimitedTrialOrCustomerDemo,
    isSelfServe,
    isManager,
    userIntegrationHash: userIntegrationHash as string,
    employeeId: employee?.id as string,
    companyId: companyId as string,
    isSalesDemoCompany: isSalesDemo,
    isDemoCompany: isDemo,
    isFreeLimitedTrialCompany: isFreeLimitedTrial,
    employeeStatus: employeeStatus || null,
    isAdmin: isAdmin || false,
    grants: queryResultData.rolesPermissionsData?.grants || {},
  });

  bootArkose({ switches, enableArkoseForApp });
  // NOTE: we don't need to handle partner users since we only enable this for checkout and TrialAccounts
  bootSegment({
    switches,
    companyId,
    isFreeLimitedTrialOrCustomerDemo,
    isFreeLimitedTrial,
    userId: userIntegrationHash as string,
    /**
     * When it's not a prospect account, we use employee work email
     * because we might use Segment to send emails and work email is where we send email notifications.
     */
    email: prospectAccount?.consumerEmail || workEmail,
  });

  checkAndBootOptimizely({ switches, isCustomerDemo: isDemo && !isSalesDemo });

  window.fireflyKillswitch = switches.firefly_killswitch;

  // Boot boomerang
  applySwitchesToPerformanceAnalytics(switches, boomerangOptions || {});
}
